(function () {
    'use strict';

    bindhq.ns('forms.select');

    bindhq.forms.select.init = function () {
        const select = $(this);

        if (select.css('display') !== 'none') {
            /* eslint-disable indent */
            const dropdownClass = select.data('dropdown-css-class')
                ? {
                      dropdownCssClass: select.data('dropdown-css-class'),
                  }
                : {};
            /* eslint-enable indent */
            const config = $.extend(
                {
                    placeholder: select.data('placeholder'),
                    width: select.data('select2width') || select.css('width'),
                    dropdownAutoWidth: false,
                },
                dropdownClass,
            );

            if (select.data('selectdisablesearch')) {
                config.minimumResultsForSearch = Infinity;
            }

            // Hack to cause select2 to open if tabbed to,
            // so that forms in v3 are more usable for keyboard users
            // https://stackoverflow.com/questions/50605279/jquery-select2-use-tab-to-select-an-option
            function openSelect2() {
                const $select2 = $(this).data('select2');
                setTimeout(function () {
                    if (!$select2.opened()) {
                        $select2.open();
                    }
                }, 0);
            }
            select
                .select2(config)
                .one('select2-focus', openSelect2)
                .on('select2-blur', function (e) {
                    $(this).one('select2-focus', openSelect2);
                });

            select.on('change', function (e) {
                // revalidate the element on change
                const form = $(e.target).closest('form');
                if (0 === form.length) {
                    // no form to validate
                    return;
                }
                const validator = form.validate();
                if (validator && validator.element) {
                    validator.element(e.target);
                }
            });
        }
    };

    bindhq.forms.select.initFreeText = function () {
        const select = $(this);

        const config = {
            placeholder: select.data('placeholder'),
            width: select.data('select2width') || select.css('width'),
            dropdownAutoWidth: true,
            createSearchChoice: function (term, data) {
                if (
                    $(data).filter(function () {
                        return this.text.localeCompare(term) === 0;
                    }).length === 0
                ) {
                    return {
                        id: term,
                        text: term,
                    };
                }
            },
            multiple: false,
            data: select.data('choices'),
        };

        config.minimumResultsForSearch = 0;

        select.select2(config);

        // We have to manually set the value displayed, as it's not done automatically,
        // when that value is not one of the choices.
        select.prev('div').find('span.select2-chosen').html(select.val());
    };
})();
