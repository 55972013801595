(function () {
    'use strict';

    bindhq.ns('pagination');

    /**
     * Handler for when pagniated data is loaded
     *
     * @param {Object} config
     * @param {String} html
     */
    bindhq.pagination.onDataLoaded = function (config, html) {
        bindhq.pagination.populate(config, html);
        config.container.trigger('pagination:dataLoaded');
    };

    /**
     * Use the HTML to populate the history
     *
     * @param {Object} config
     * @param {String} html
     */
    bindhq.pagination.populate = function (config, html) {
        const container = config.container;
        const items = $('.pagination-item, .pagination-item-content', html).not(
            '.empty',
        );
        const newTable = $('.table-listing', html);

        const newTotalNum = newTable.data('paginationTotal');

        if (undefined !== newTotalNum) {
            $(container).data('paginationTotal', Math.max(1, newTotalNum));
        }

        const newCsvAction = $('.csv-action', html);

        if (0 < newCsvAction.length) {
            const wrapper = container.closest('.table-listing-wrapper');
            $('.csv-action', wrapper).replaceWith(newCsvAction);
        }

        const content = $('.pagination-content', container);

        content.html(items);

        // init is not in portal so needs a check
        if (bindhq.init) {
            items.each(bindhq.init);
        }

        config.loading = false;

        bindhq.loader.hidePartial(content.closest('div'));
    };

    /**
     * Update browser history
     *
     * @param {String} url
     */
    bindhq.pagination.updateHistory = function (url) {
        const path = url.replace(/https?:\/\/[^/]+/i, '');
        history.pushState({ type: 'pagination' }, document.title, path);
    };

    /**
     * Load another page
     *
     * @param {Object} config
     * @param {Integer} inc
     */
    bindhq.pagination.loadPage = function (config, inc, page) {
        const newPage = page || config.page + inc;
        const maxPages = config.totalItems
            ? Math.ceil(config.totalItems / config.perPage)
            : null;

        if (newPage > 0 && (newPage <= maxPages || !maxPages)) {
            const container = config.container;
            const pageParameter =
                container.data('pagination-page-parameter') || 'page';

            const success = _.partial(bindhq.pagination.onDataLoaded, config);

            config.loading = true;

            let data = new FormData();

            // If is is inside a form (filters) it submits it
            const form = container.closest('form');
            if (form.length) {
                data = new FormData(form[0]);
            }

            config.page = newPage;
            data.set(pageParameter, config.page);

            const query = new URLSearchParams(data).toString(); // Output: foo=1&bar=2
            const url = `${config.url}?${query}`;

            const params = {
                url: url,
                success: success,
                complete: function () {
                    if (config.shouldUpdateHistory) {
                        bindhq.pagination.updateHistory(this.url + '&ajax=1');
                    }
                },
            };

            $.ajax(params);

            const content = $('.pagination-content', container);
            const loaderInsertPoint = content.closest('div');

            bindhq.loader.showPartial(loaderInsertPoint);
        }
    };

    /**
     * @param {Object} config
     */
    bindhq.pagination.onNextPage = function (config) {
        if (config.container.is(':visible')) {
            if (!config.loading) {
                bindhq.pagination.loadPage(config, 1);
            }
        }
    };

    /**
     * @param {Object} config
     */
    bindhq.pagination.onPreviousPage = function (config) {
        if (config.container.is(':visible')) {
            if (!config.loading) {
                bindhq.pagination.loadPage(config, -1);
            }
        }
    };

    /**
     * @param {Object} config
     */
    bindhq.pagination.onRefreshPage = function (config) {
        if (config.container.is(':visible')) {
            bindhq.pagination.loadPage(config, 0, 1);
        }
    };

    /**
     * @param {Object} config
     */
    bindhq.pagination.updatePager = function (config) {
        if (config.pager) {
            const items = $('.pagination-item', config.container);
            const maxPages = config.totalItems
                ? Math.ceil(config.totalItems / config.perPage)
                : null;
            const html = bindhq.util.template(
                bindhq.tpl.table_pagination_numerated,
                {
                    currentItems: config.container.data('paginationTotal'),
                    currentPageIndex: config.page,
                    perPage: config.perPage,
                },
            );

            config.pager.html(html);

            const input = $('.table-pagination-current', config.pager);
            input.attr('size', input.val().length);

            if (
                (maxPages && maxPages > config.page) ||
                items.length === config.perPage
            ) {
                $('.btn-pagination-next', config.pager).on(
                    'click',
                    config.nextPage,
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth',
                    }),
                );
                $('.btn-pagination-next', config.pager).removeClass('disabled');
            } else {
                $('.btn-pagination-next', config.pager).addClass('disabled');
            }

            if (config.page > 1) {
                $('.btn-pagination-previous', config.pager).on(
                    'click',
                    config.previousPage,
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth',
                    }),
                );
                $('.btn-pagination-previous', config.pager).removeClass(
                    'disabled',
                );
            } else {
                $('.btn-pagination-previous', config.pager).addClass(
                    'disabled',
                );
            }
        }
    };

    /**
     * @param {Object} config
     */
    bindhq.pagination.onUpdateCsv = function (config) {
        const container = config.container;
        const form = container.closest('form');

        if (form.length !== 0) {
            const csvLink = $('.pagination-csv-link');

            if (csvLink.length !== 0) {
                const queryString = 'noPagination=1&' + form.serialize();
                const currentUrl = csvLink.attr('href');
                const qsIndex = currentUrl.indexOf('?');
                const updatedUrl =
                    qsIndex === -1
                        ? currentUrl + '?' + queryString
                        : currentUrl.substring(0, qsIndex) + '?' + queryString;

                csvLink.attr('href', updatedUrl);
            }
        }
    };

    bindhq.pagination.initHistory = function (url) {
        if (!history.state || history.state.type !== 'pagination') {
            const path = url.replace(/https?:\/\/[^/]+/i, '');
            history.replaceState({ type: 'pagination' }, document.title, path);
        }

        $(window).on('popstate', function (e) {
            if (history.state && history.state.type === 'pagination') {
                window.location.reload();
            }
        });
    };

    /**
     * Initialise pagination
     */
    bindhq.pagination.init = function () {
        const container = $(this);
        const pager = $('<div class="pager-container"></div>');
        const wrapper = container
            .parent()
            .hasClass('table-listing-container-wide')
            ? container.parent()
            : container;

        wrapper.after(pager);

        // If the pagination history setting is set, use it, else default
        // to checking for a modal for some reason
        const optionHistory = container.data(
            'pagination-should-update-history',
        );
        const shouldUpdateHistory =
            optionHistory === undefined
                ? container.closest('.modal').length === 0
                : !!optionHistory;

        const baseUrl =
            container.data('pagination-url') || document.location.pathname;
        const pageParameterName =
            container.data('pagination-page-parameter') || 'page';
        const pageParameter = bindhq.util.queryParam(pageParameterName);
        const page = pageParameter ? parseInt(pageParameter) : 1;
        const config = {
            loading: false,
            url: baseUrl,
            page: page,
            pager: pager,
            container: container,
            totalItems: container.data('pagination-total'),
            perPage: container.data('pagination-per-page'),
            shouldUpdateHistory,
        };

        config.nextPage = _.partial(bindhq.pagination.onNextPage, config);

        config.previousPage = _.partial(
            bindhq.pagination.onPreviousPage,
            config,
        );

        config.refreshPage = _.partial(bindhq.pagination.onRefreshPage, config);

        config.updatePager = _.partial(bindhq.pagination.updatePager, config);

        config.updateCsv = _.partial(bindhq.pagination.onUpdateCsv, config);

        container.on('refreshPage', config.refreshPage);
        container.on('refreshPage', config.updateCsv);
        container.on('pagination:dataLoaded', config.updatePager);

        bindhq.pagination.updatePager(config);
        if (shouldUpdateHistory) {
            bindhq.pagination.initHistory(baseUrl + document.location.search);
        }
    };
})();
